<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Member List</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New Staff
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        :items="userItems"
        :fields="fields"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        striped
        responsive
        @row-clicked="showDetails"
      >
        <template #cell(avatar)="data">
          <b-avatar
            v-if="data.value === null || data.value === ''"
            :text="getUserText(data.item.first_name, data.item.last_name)"
            variant="success"
          />
          <b-avatar
            v-else
            :src="getImage(data.value)"
            variant="light-success"
          />
        </template>

        <template #cell(fullName)="data">
          {{ data.value }}
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <!-- <template
          #cell(can_login)="data"
        >
          <b-form-checkbox
            :checked="data.item.user_access.can_login === true ? 'true' : 'false'"
            class="custom-control-success"
            name="check-button"
            switch
            @change="toggleUser($event, data.item.id)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template> -->
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="per_page"
        size="lg"
        class="mt-3 pagination-success"
        align="center"
        @change="onPageChange"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate Member' : 'Add New Member'"
      hide-footer
      size="huge"
      @hide="hide"
    >
      <b-card-text>
        <Auth
          type="member"
          :hide="hide"
          :show-details="showDetails"
          :update="is_update"
          :edited="is_edited"
          :disable-editted="disableEditted"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BAvatar, BBadge, BFormCheckbox, BPagination,
  BFormInput,
  BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Auth from './components/RegisterUser/Auth.vue'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BAvatar,
    BBadge,
    BFormCheckbox,
    BPagination,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    Auth,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      fields: [{ key: 'id', label: '#ID' }, { key: 'avatar', label: 'photo' }, { key: 'fullName', label: 'Fullname', sortable: true }, { key: 'email', label: 'email', sortable: true }, 'phone'],
      currentPage: 1,
      per_page: 10,
      sortBy: 'id',
      sortDesc: false,
      searchTerm: '',
      filter: null,
      filterOn: [],
      is_edited: false,
    }
  },
  computed: {
    users() {
      const member = this.$store.state.user.members
      return member
    },
    userItems() {
      return this.users.map(item => ({
        ...item,
        fullName: `${item.first_name} ${item.last_name}`,
      }))
    },
    rows() {
      return this.$store.state.user.total
    },
    register: {
      get() {
        return this.$store.state.auth.userRegister
      },
      set(value) {
        return value
      },
    },
  },
  watch: {
    '$store.state.auth.userRegister': {
      handler() {
        this.is_edited = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
    getUsers() {
      return this.$store.dispatch('user/getMembers', { page: 1, per_page: this.per_page, user_type: 'member' })
    },
    onPageChange(page) {
      return this.$store.dispatch('user/getMembers', { page, per_page: this.per_page, user_type: 'member' })
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    toggleUser(status, id) {
      const data = { user_id: id, can_login: status }
      return this.$store.dispatch('user/toggleUser', data)
    },
    disableEditted() {
      this.is_edited = false
    },
    hide(e) {
      if (this.is_edited === true) {
        e.preventDefault()
        this.$swal({
          title: 'Do you want to save changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.is_edited = false
            this.updateUser()
            this.$bvModal.hide('app-modal')
          } else if (result.dismiss === 'cancel') {
            this.is_edited = false
            this.$bvModal.hide('app-modal')
            const dom = this
            setTimeout(() => {
              dom.$store.dispatch('auth/emptyData')
            }, 500)
          }
        })
      } else {
        this.$bvModal.hide('app-modal')
        this.is_edited = false
        const dom = this
        setTimeout(() => {
          dom.$store.dispatch('auth/emptyData')
        }, 500)
      }
    },
    updateUser() {
      this.$store.dispatch('auth/updateRegisterProfile', this.register)
      const dom = this
      setTimeout(() => {
        dom.$store.dispatch('user/getMembers', { page: 1, per_page: 10, user_type: 'member' })
      }, 100)
    },
    showDetails(item) {
      this.register.id = item.id
      this.register.first_name = item.first_name
      this.register.last_name = item.last_name
      this.register.phone = item.phone
      this.register.email = item.email
      this.register.address1 = item.address1
      this.register.city = item.city
      this.register.state = item.state
      this.register.zip_code = item.zip_code
      this.register.county = item.county
      this.register.user_access = item.user_access
      this.register.member = item.member
      this.register.profession = item.profession
      this.$store.dispatch('review/getMemberReviews', { member_id: item.user_access.id, per_page: 10, page: 1 })
      this.is_update = true
      this.showModal()
      const dom = this
      setTimeout(() => {
        dom.is_edited = false
      }, 1000)
    },
  },
}
</script>

<style>

</style>
